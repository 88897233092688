import { format, isValid } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

interface Props {
  date: string | Date;
  timeZone?: string;
}

const formatDate = ({ date, timeZone }: Props) => {
  if (!date || date === "None" || !isValid(date)) {
    return date.toString();
  } else {
    let zonedDate = utcToZonedTime(new Date(date), timeZone);
    return format(zonedDate, "dd/MM/yyyy, HH:mm:ss");
  }
};

export default formatDate;
