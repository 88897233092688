import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AuthCard from "../../Components/AuthCard";
import Toast from "../../Components/Toast";

const SignIn = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  let navigate = useNavigate();
  let { currentUser, fbSignIn } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate("/dashboard");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleSignin = async () => {
    setLoading(true);
    await fbSignIn().then((res: any) => {
      if (res !== 200) {
        setError(res);
        const timer = setTimeout(() => {
          setLoading(false);
          setError(null);
        }, 3000);
        return () => clearTimeout(timer);
      } else {
        const timer = setTimeout(() => {
          setLoading(false);
          setError(null);
        }, 3000);
        return () => clearTimeout(timer);
      }
    });
  };

  return (
    <>
      <Toast
        loading={loading}
        warning={Boolean(error)}
        message={error ? "Error verifying login" : "Loading. . ."}
      />
      <AuthCard>
        <Box sx={{ pt: 4 }} />
        <Button variant="contained" fullWidth onClick={() => handleSignin()}>
          <Typography sx={{ my: 2 }}>Login</Typography>
        </Button>
      </AuthCard>
    </>
  );
};

export default SignIn;
