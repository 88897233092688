import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  header: string;
  children: any;
  downloadPath?: null | string;
  loading?: boolean;
  handleFileDownload?: any;
}

function Collapsible({
  header,
  children,
  downloadPath,
  loading,
  handleFileDownload,
}: Props) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Button
          disableRipple
          onClick={handleClick}
          sx={{ display: "flex", textTransform: "none" }}
        >
          <Typography sx={{ fontWeight: "600" }}>{header}</Typography>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
        {handleFileDownload &&
          (downloadPath ? (
            <Button
              size="small"
              endIcon={<SaveAltIcon />}
              component="a"
              download
              href={downloadPath}
              sx={{ textTransform: "none" }}
            >
              Download
            </Button>
          ) : loading ? (
            <Button
              size="small"
              endIcon={<CircularProgress size="18px" />}
              disabled={true}
              sx={{ textTransform: "none" }}
            >
              Zipping...
            </Button>
          ) : (
            <Button
              size="small"
              endIcon={<FolderZipIcon />}
              onClick={handleFileDownload}
              sx={{ textTransform: "none" }}
            >
              Zip folder
            </Button>
          ))}
      </Box>
      <Collapse in={open}>{children}</Collapse>
    </>
  );
}

export default Collapsible;
