import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function SizeWarning() {
  return (
    <Tooltip title="File size exceeds 100mb and will not be included in .zip">
      <IconButton>
        <ErrorOutlineIcon color="warning" />
      </IconButton>
    </Tooltip>
  );
}

export default SizeWarning;
