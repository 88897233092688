import React, { useState } from "react";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subDays,
  addDays,
} from "date-fns";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Popper from "@mui/material/Popper";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { arrayToExcel } from "../../../../Helpers/arrayToExcel";

const shortcutsItems: PickersShortcutsItem<any>[] = [
  {
    label: "This Week",
    getValue: () => {
      const today = new Date();
      return [startOfWeek(today), endOfWeek(today)];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = new Date();
      const prevWeek = subDays(today, 7);
      return [startOfWeek(prevWeek), endOfWeek(prevWeek)];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = new Date();
      return [subDays(today, 7), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = new Date();
      return [startOfMonth(today), endOfMonth(today)];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = new Date();
      const startOfNextMonth = addDays(endOfMonth(today), 1);
      return [startOfNextMonth, endOfMonth(startOfNextMonth)];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

interface Props {
  jobs: any;
  database: string;
  timeZone: any;
}

function FeedbackExportOptions({ jobs, database, timeZone }: Props) {
  const [value, setValue] = useState<any>([null, null]);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilename = () => {
    let now = new Date();
    let isoDate = now.toISOString();
    return `${database}_export_${isoDate}.xls`;
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const setNewDates = (values: any[]) => {
    values.map((i: any) => {
      if (i !== null) {
        return i.toLocaleString("en-US", { timeZone: timeZone?.tzCode });
      } else {
        return i;
      }
    });
    setValue(values);
  };

  return (
    <>
      <Button
        // disabled={!jobs}
        // onClick={() =>
        //   arrayToExcel.convertArrayToTable(jobs, handleFilename())
        // }
        onClick={handleClick}
        size="small"
        startIcon={<SaveAltIcon fontSize="small" />}
      >
        Feedback Export
      </Button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <Popper
          open={open}
          placement="bottom"
          disablePortal={false}
          anchorEl={anchorEl}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
        >
          <Paper>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  pt: 1,
                  mx: 1,
                }}
              >
                <IconButton onClick={handleClick}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box sx={{}}>
                <StaticDateRangePicker
                  slotProps={{
                    shortcuts: {
                      items: shortcutsItems,
                    },
                    actionBar: { actions: [] },
                  }}
                  calendars={2}
                  value={value}
                  onChange={(newValue) => setNewDates(newValue)}
                />
              </Box>
              <Box
                sx={{
                  m: 2,
                  pb: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() =>
                    arrayToExcel.convertArrayToTable(
                      jobs,
                      handleFilename(),
                      value
                    )
                  }
                >
                  Export
                </Button>
              </Box>
            </Box>
          </Paper>
        </Popper>
      </Backdrop>
    </>
  );
}

export default FeedbackExportOptions;
