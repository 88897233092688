import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import JobDetail from "../../JobDetail";

interface Props {
  jobs: any;
  database: string;
}

function DBAccordion({ jobs, database }: Props) {
  if (!jobs || jobs.length === 0) {
    return null;
  } else {
    return (
      <>
        <Accordion sx={{ my: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">· {database}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {jobs.map((i: any, index: number) => {
              return (
                <JobDetail
                  key={index}
                  job={i}
                  job_id={i.id}
                  database={i.database}
                />
              );
            })}
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
}

export default DBAccordion;
