import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface Props {
  newJobs: null | number;
}

function NewItemsAlert({ newJobs }: Props) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "32px",
        right: "24px",
        zIndex: "9999",
        background: "#5c76aa",
        textAlign: "center",
        display: newJobs === 0 ? "none" : "inline-flex",
        justifyContent: "center",
        verticalAlign: "middle",
        height: "20px",
        minWidth: "20px",
        borderRadius: "10px",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: "white",
          padding: "0px 6px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignContent: "center",
          alignItems: "center",
          fontWeight: "500",
          borderRadius: "10px",
          zIndex: "1",
          top: "0",
          right: "0",
        }}
      >
        {newJobs}
      </Typography>
    </Box>
  );
}

export default NewItemsAlert;
