// https://github.com/Linda-Ikechukwu/Blog-Posts-Demo-Apps/blob/master/export-array-demo/src/Components/ArrayToExcel/ArrayToExcel.js
import copy from "./copy";
import { parseISO } from "date-fns";

const hiddenHeaders: any[] = [
  "progress",
  "progress_message",
  "file_name",
  "extra_files",
  "zip_name",
  "id",
  "weariness",
  "iOS_tocken",
  "shoe_size",
  "submit_date",
  "measurer",
  "measurerValidation",
  "measure_zip_name",
  "notes",
  "app_version",
  "measurerValidation",
  "ankleCircumferenceR",
  "ankleCircumferenceL",
  "calfCircumferenceR",
  "calfCircumferenceL",
  "calfLengthL",
  "calfLengthR",
  "ankleLengthR",
  "ankleLengthL",
  "thighCircumferenceR",
  "thighCircumferenceL",
  "legLengthR",
  "legLengthL",
  "hipCircumference",
  "appVersion",
  "app_version",
  "timings",
  "commits",
];

export const arrayToExcel = (function () {
  //STEP 2: Append Table data to Spreadsheet XML Template.
  const createXMLTable = (table: string, fileName: string) => {
    const xmlTable = `
        <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"
            xmlns="http://www.w3.org/TR/REC-html40"
        >
           <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"/>
           <head>
              <xml>
                <x:ExcelWorkbook>
                    <x:ExcelWorksheets>
                        <x:ExcelWorksheet>
                            <x:Name>${fileName}</x:Name>
                            <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
                        </x:ExcelWorksheet>
                    </x:ExcelWorksheets>
                </x:ExcelWorkbook>
              </xml>
           </head>
           <body>
             ${table}
           </body>
        </html> `;
    return xmlTable;
  };

  //STEP 3: Create fileURL from XML template for download
  const createFileUrl = (xmlTable: string) => {
    const tableBlob = new Blob([xmlTable], {
      type: "application/vnd.ms-excel;base64,",
    });
    const downloadURL = URL.createObjectURL(tableBlob);
    return downloadURL;
  };

  //STEP 5: Create download link on button click
  const downloadFile = (downloadURL: string, fileName: string) => {
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.download = fileName;
    downloadLink.href = downloadURL;
    downloadLink.click();
  };

  //STEP 1: Convert Array to HTML Table.
  return {
    convertArrayToTable: async (
      apiArray: any,
      fileName: string,
      dates: any[]
    ) => {
      let filteredArray = apiArray
        .filter((e) => e.feedback)
        .filter((e) => typeof e.feedback === "object");

      let datesBoolean = Boolean(dates.filter((e: any) => e !== null).length);

      if (datesBoolean) {
        let filteredByDates = [];
        let startDate = dates[0] ? new Date(dates[0]) : new Date(null);
        let endDate = dates[1] ? new Date(dates[1]) : new Date();

        startDate.setHours(0, 0, 0);
        endDate.setHours(23, 59, 59);

        filteredArray.forEach((i: any) => {
          let initDate = parseISO(i.init_date);
          if (initDate >= startDate && initDate <= endDate) {
            filteredByDates.push(i);
          }
        });
        filteredArray = filteredByDates;
      }

      let headers = [];

      filteredArray.forEach((i: any) => {
        Object.keys(i).forEach((e) => {
          if (!headers.includes(e)) {
            if (!hiddenHeaders.includes(e)) {
              headers.push(e);
            }
          }
        });
      });

      const getHeaders = () => {
        let returnData = [];
        headers.forEach((i: any) => {
          returnData.push(`<td>${i}</td>`);
        });

        return returnData;
      };

      const tableHeaders = `<tr>
        ${getHeaders().join("")}
      </tr>`;

      const getRow = (job: any) => {
        let returnData = [];

        headers.forEach((i: any) => {
          returnData.push(
            `<td>${i === "feedback" ? feedbackSubtable(job[i]) : job[i]}</td>`
          );
        });

        return returnData;
      };

      const tableRows = filteredArray
        .map((job: any) => [
          `<tr>
        ${getRow(job).join("")}
        </tr>`,
        ])
        .join("");

      const table = `<table>${tableHeaders}${tableRows}</table>`.trim();
      const xmlTable = createXMLTable(table, fileName);
      const downloadURL = createFileUrl(xmlTable);
      downloadFile(downloadURL, fileName);
    },
  };
})();

const convertArrayToMiniTable = (obj: any) => {
  let tableHeaders = `<tr>
  ${Object.keys(obj)
    .map((key) => `<td>${key}</td>`)
    .join("")}</tr>`;

  const tableRows = `<tr>${Object.keys(obj)
    .map((key) => `<td>${obj[key]}</td>`)
    .join("")}</tr>`;

  return `<table>${tableHeaders}${tableRows}</table>`;
};

const feedbackSubtable = (obj: any) => {
  if (!obj) {
    return "";
  }

  let objCopy = copy(obj);
  let headers = [];

  Object.keys(objCopy).forEach((key) => {
    if (key === "images") {
      typeof obj[key] === "object" &&
        obj[key].forEach((i) => {
          if (typeof i === "object") {
            Object.keys(i).forEach((e) => {
              !headers.includes(e) && headers.push(e);
            });
          }
        });
    }
  });

  let subtableHeaders = `<tr>${headers
    .map((key) => `<td>${key}</td>`)
    .join("")}</tr>`;

  // Object.keys(obj).map((key) =>
  //   Array.isArray(obj[key])
  //     ? obj[key].map((i) =>
  //         Object.keys(i).map((e) => {
  //           console.log(e, i[e]);
  //         })
  //       )
  //     : ""
  // );

  let subtableBody = Object.keys(obj)
    .map((key) => [
      Array.isArray(obj[key])
        ? obj[key]
            .map(
              (i) =>
                `<tr>${Object.keys(i)
                  .map(
                    (e) => `<td>
      ${
        typeof i[e] === "object" ? convertArrayToMiniTable(i[e]) : i[e]
        // : headers.includes(e)
        // ? i[e]
        // : i[e] === null || i[e] === ""
        // ? ""
        // : ""
      }

      </td>`
                  )
                  .join("")}</tr>`
            )
            .join("")
        : "",
    ])
    .join("");
  const subTable = `<table>${subtableHeaders}${subtableBody}</table>`.trim();

  return subTable;
};
