const header = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": `${process.env.REACT_APP_SERVER_URL}`,
};

interface ConfigProps {
  id: string;
  body: any;
  token: any;
}

export const patchUser = async ({ id, body, token }: ConfigProps) => {
  const requestOptions = {
    method: "PATCH",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/users/${id}`;
  const res = await fetch(URL, requestOptions);
  if (res.status !== 200) {
    return res.status;
  }
  const json = await res.json();
  return json;
};

export const postUser = async ({ id, body, token }: ConfigProps) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/users/${id}`;
  const res = await fetch(URL, requestOptions);
  const json = await res.json();
  return json;
};

interface GetUserProps {
  id: string;
  token: any;
}

export const getUser = async ({ id, token }: GetUserProps) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/users/${id}`;
  const res = await fetch(URL, requestOptions);
  const json = await res.json();
  return json;
};
