import React, { useState, useEffect } from "react";
import { useJobDetails } from "../../../Context/JobDetailContext";
import { useAuth } from "../../../Context/AuthContext";
import {
  DataGridPro as MUIDataGrid,
  GridActionsCellItem,
  GridPagination,
  GridFilterInputValueProps,
  getGridStringOperators,
  GridFilterModel,
} from "@mui/x-data-grid-pro";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowUpIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownIcon from "@mui/icons-material/ArrowDownward";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import Tooltip from "@mui/material/Tooltip";
import GridToolbar from "./GridToolbar";
import NestedDataPopover from "./NestedDataPopover";
import FeedbackDialog from "./FeedbackDialog";
import { flattenObject } from "../../../Helpers/flattenObj";
import copy from "../../../Helpers/copy";
import formatDate from "../../../Helpers/formatDate";
import emptyColumns from "../../../mockData/emptyColumns";

interface Props {
  jobs: any;
  loading: boolean;
  backgroundLoad: boolean;
  database: string;
  getJob: any;
  lastUpdate: any;
}

function DataTable({ jobs, loading, database, getJob, lastUpdate }: Props) {
  const { addJobDetails, jobDetails } = useJobDetails();
  const { getColumnVisibility, updateColumnVisibility } = useAuth();
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [timeZone, setTimezone] = useState<any>(null);
  const [globalTimezone, setGlobalTimezone] = useState<any>({
    label: "Europe/London (GMT+00:00)",
    tzCode: "Europe/London",
    name: "(GMT+00:00) London, Birmingham, Liverpool, Sheffield, Bristol",
    utc: "+00:00",
  });
  const [tzInputValue, setTzInputValue] = useState<any>("");
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });
  const [pinnedRows, setPinnedRows] = React.useState<{
    top: any;
    bottom: any;
  }>({
    top: [],
    bottom: [],
  });

  const handleJobDetails = ({ job }: any) => {
    job.database = database;
    addJobDetails({ job: job });
  };

  const getUniqueRowValues = (rows: any, key: string) => {
    let allValues = [];

    rows.forEach((i: any) => {
      Object.keys(i).forEach((e: any) => {
        if (e === key) {
          allValues.push(i[e]);
        }
      });
    });

    let setValues = Array.from(new Set(allValues));
    setValues = setValues.filter(function (e) {
      return e;
    });
    return setValues;
  };

  const AddJobButton = ({ job }: any) => {
    return (
      <>
        <IconButton onClick={() => handleJobDetails({ job: job.row })}>
          <PlaylistAddIcon />
        </IconButton>
      </>
    );
  };

  const FeedbackDialogButton = ({ job_id, data, database }: any) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
      setOpen(!open);
    };

    return (
      <IconButton
        onClick={() => handleOpen()}
        disabled={!data?.feedback?.images}
      >
        <AnalyticsOutlinedIcon />
        <FeedbackDialog
          open={open}
          handleOpen={handleOpen}
          job_id={job_id}
          data={data?.feedback?.images}
          database={database}
        />
      </IconButton>
    );
  };

  useEffect(() => {
    if (!jobs) {
      return;
    }
    let dataCopy = copy(jobs);
    dataCopy.length &&
      dataCopy?.map((i: any) => {
        return flattenObject(i);
      });

    setRows(dataCopy);
  }, [jobs]);

  const localizeDate = (date: string) => {
    if (!globalTimezone) {
      return date;
    } else {
      return formatDate({ date: date, timeZone: globalTimezone.tzCode });
    }
  };

  useEffect(() => {
    if (!rows) {
      return;
    }
    let uniqueKeys = Object.keys(
      rows.reduce(function (result: any, obj: any) {
        return Object.assign(result, obj);
      }, {})
    );

    let keys_to_filter: any = null;

    emptyColumns.forEach((i: any) => {
      if (i.database === database) {
        keys_to_filter = i.columns;
      }
    });

    let filteredKeys = uniqueKeys.filter(
      (item: any) => !keys_to_filter.includes(item)
    );

    let columnData = filteredKeys.map((i: any) => {
      if (
        i === "timings" ||
        i === "commits" ||
        i === "feedback" ||
        i === "full_traceback"
      ) {
        return {
          field: i,
          headerName: i,
          sortable: false,
          filterable: false,
          align: "center",
          width: 76,
          renderCell: (params: any, index: number) => {
            return <NestedDataPopover key={index} data={params.value} />;
          },
        };
      } else if (i === "_id") {
        return {
          field: i,
          width: 210,
          // renderHeaderFilter: (params: any) => console.log(params),
        };
      } else if (i === "init_date" || i === "capture_date_iso") {
        return {
          field: i,
          renderCell: (params: any, index: number) => {
            return <>{localizeDate(params.value)}</>;
          },
        };
      } else if (
        i === "status" ||
        i === "progress" ||
        i === "progress_message" ||
        i === "error_code" ||
        i === "country" ||
        i === "region" ||
        i === "language" ||
        i === "app_version" ||
        i === "stage" ||
        i === "product_type" ||
        i === "appVersion" ||
        i === "phone_model" ||
        i === "body_part" ||
        i === "selected_model"
      ) {
        return {
          field: i,
          valueGetter: (params: any) => {
            return `${params.value}`;
          },
          filterOperators: getGridStringOperators()
            .filter((operator) => operator.value !== "isAnyOf")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                ? RatingInputValue
                : undefined,
            })),
        };
      } else {
        return {
          field: i,
        };
      }
    });

    let details = {
      field: "details",
      headerName: "Details",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 60,
      renderCell: (params: any, index: number) => {
        return <AddJobButton key={index} job={params} />;
      },
    };

    let actions = {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params: any) => {
        const isPinnedTop =
          pinnedRows.top.filter((item: any) => item?.id === params?.row?.id)
            .length > 0;
        const isPinnedBottom =
          pinnedRows.bottom.filter((item: any) => item?.id === params?.row?.id)
            .length > 0;

        if (isPinnedTop || isPinnedBottom) {
          return [
            <GridActionsCellItem
              label="Unpin"
              icon={
                <Tooltip title="Unpin">
                  {isPinnedTop ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </Tooltip>
              }
              onClick={() =>
                setPinnedRows((prevPinnedRows) => ({
                  top: prevPinnedRows.top.filter(
                    (rowId: any) => rowId !== params.row
                  ),
                  bottom: prevPinnedRows.bottom.filter(
                    (rowId: any) => rowId !== params.row
                  ),
                }))
              }
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Pin at the top">
                <ArrowUpIcon />
              </Tooltip>
            }
            label="Pin at the top"
            onClick={() =>
              setPinnedRows((prevPinnedRowsIds) => ({
                ...prevPinnedRowsIds,
                top: [...prevPinnedRowsIds.top, params.row],
              }))
            }
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Pin at the bottom">
                <ArrowDownIcon />
              </Tooltip>
            }
            label="Pin at the bottom"
            onClick={() =>
              setPinnedRows((prevPinnedRows) => ({
                ...prevPinnedRows,
                bottom: [...prevPinnedRows.bottom, params.row],
              }))
            }
          />,
        ];
      },
    };

    let feedbackActions = {
      field: "feedbackActions",
      headerName: "Feedback",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: "center",
      width: 90,
      renderCell: (params: any, index: number) => {
        return (
          <FeedbackDialogButton
            key={index}
            data={params.row}
            job_id={params.id}
            database={database}
          />
        );
      },
    };

    setColumns([feedbackActions, actions, details, ...columnData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, jobDetails, globalTimezone, pinnedRows]);

  const feedbackExcelCheck = () => {
    if (database === "sigvaris-qa-db" || database === "sigvaris-prod") {
      return true;
    } else {
      return false;
    }
  };

  function RatingInputValue(
    props: GridFilterInputValueProps & {
      headerFilterMenu: React.ReactNode;
      clearButton: React.ReactNode;
    }
  ) {
    const { item, applyValue, headerFilterMenu } = props;
    const ratingRef: React.Ref<any> = React.useRef(null);
    // React.useImperativeHandle(focusElementRef, () => ({
    //   focus: () => {
    //     ratingRef?.current
    //       .querySelector(`input[value="${item.value || ""}"]`)
    //       .focus();
    //   },
    // }));

    const handleFilterChange = (event: any, newValue: string) => {
      applyValue({ ...item, value: newValue });
    };

    return (
      <React.Fragment>
        {headerFilterMenu}
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
            height: "100%",
            width: "inherit",
          }}
        >
          <Autocomplete
            fullWidth
            autoComplete
            options={getUniqueRowValues(rows, item.field)}
            onInputChange={(event: any, newValue: string) =>
              handleFilterChange(event, newValue)
            }
            value={item?.value}
            ref={ratingRef}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={"Value"}
                InputLabelProps={{ shrink: true }}
                sx={{ textTransform: "capitalize" }}
              />
            )}
            sx={{ display: "flex", mt: "0px" }}
          />
        </Box>
      </React.Fragment>
    );
  }

  return (
    <Box
      sx={{
        height: "calc(100vh - 40px)",
        minHeight: "600px",
        width: "calc(100vw - 254px)",
      }}
    >
      <MUIDataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        pinnedRows={pinnedRows}
        // unstable_headerFilters
        columnVisibilityModel={getColumnVisibility(database)}
        onColumnVisibilityModelChange={(newModel) =>
          updateColumnVisibility(database, newModel)
        }
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        pageSizeOptions={[50, 100, 200]}
        pagination={true}
        slots={{
          toolbar: GridToolbar,
          loadingOverlay: LinearProgress,
          footer: () => (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mx: 2,
                }}
              >
                <Typography variant="body2">
                  Last update: {localizeDate(lastUpdate)}
                </Typography>
                <GridPagination />
              </Box>
            </>
          ),
        }}
        slotProps={{
          toolbar: {
            getJob: getJob,
            database: database,
            setTimezone: setTimezone,
            timeZone: timeZone,
            globalTimezone: globalTimezone,
            setGlobalTimezone: setGlobalTimezone,
            tzInputValue: tzInputValue,
            setTzInputValue: setTzInputValue,
            feedbackExport: feedbackExcelCheck(),
            jobs: jobs,
          },
        }}
      />
    </Box>
  );
}

export default DataTable;
