import React, { createContext, useContext, useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { signOut, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import provider from "../auth_msft_create_provider";
import { postUser, getUser, patchUser } from "../Services/Users/Users";
import copy from "../Helpers/copy";
// To get the current auth level call .getIdTokenResult(); on currentUser

// notes for future ana:
// to debounce the state changes on column visibility you should use:
// https://www.npmjs.com/package/use-debounce

const AuthContext = createContext<any>(null);

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }: any) {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [columnVisibilityModels, setColumnVisibilityModels] = useState<any>([
    { database: "sigvaris-qa-db", model: {} },
    { database: "sigvaris-prod", model: {} },
    { database: "cartier-test", model: {} },
    { database: "cartierprodwest", model: {} },
  ]);

  const [debouncedVisibility] = useDebounce(columnVisibilityModels, 1500);

  useEffect(() => {
    if (auth.currentUser) {
      setCurrentUser(auth.currentUser);
    }
  }, []);

  const fbSignIn = async () => {
    return await signInWithPopup(auth, provider)
      .then((result) => {
        setCurrentUser(auth.currentUser);
        let creationTime = auth.currentUser.metadata.creationTime;
        let lastSignInTime = auth.currentUser.metadata.lastSignInTime;

        if (creationTime === lastSignInTime) {
          let uid = auth.currentUser.uid;
          setSaving(true);
          auth.currentUser.getIdToken().then((res: any) => {
            let tmpUser = {
              uid: uid,
              creationTime: creationTime,
              columnVisibilityModels: columnVisibilityModels,
            };

            postUser({ id: uid, body: tmpUser, token: res })
              .then(() => {
                setSaving(false);
              })
              .catch((err: any) => {
                setError(`${err}`);
                setSaving(false);
              });
          });
        }
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        // const credential = OAuthProvider.credentialFromResult(result);
        // const accessToken = credential?.accessToken;
        // const idToken = credential?.idToken;
      })
      .catch((err) => {
        console.log(err);
        // Handle error.
      });
  };

  const logout = () => {
    setCurrentUser(null);
    signOut(auth);
  };

  const patchUserData = async (body: any) => {
    if (!currentUser) {
      return;
    }
    setSaving(true);
    let token = await currentUser.getIdToken();
    let id = currentUser.uid;

    await patchUser({ id: id, token: token, body: body })
      .then((res) => {
        if (res === 404) {
          postUser({ id: id, token: token, body: body });
        }
        setSaving(false);
      })
      .catch((err: any) => {
        setError(`${err}`);
        setSaving(false);
      });
  };

  const getUserData = async () => {
    if (!currentUser) {
      return null;
    }
    let token = await currentUser.getIdToken();
    let id = currentUser.uid;

    return await getUser({ id: id, token: token })
      .then((res: any) => {
        if (res?.columnVisibilityModels) {
          setColumnVisibilityModels(res.columnVisibilityModels);
        }
        return res;
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getColumnVisibility = (database: string) => {
    let state_copy = copy(columnVisibilityModels);

    return state_copy.filter((item: any) => item.database === database)[0]
      .model;
  };

  const updateColumnVisibility = (database: string, model: any) => {
    let state_copy = copy(columnVisibilityModels);

    state_copy.forEach((i: any) => {
      if (i.database === database) {
        i.model = model;
      }
    });

    setColumnVisibilityModels(state_copy);
  };

  const getAndSetUserData = async () => {
    await getUserData()
      .then((res: any) => {
        if (res?.columnVisibilityModels) {
          setColumnVisibilityModels(res.columnVisibilityModels);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    } else {
      getAndSetUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  // @TODO:
  // useEffect for patch when column visibility is changed

  useEffect(() => {
    if (!currentUser) {
      return;
    } else {
      patchUserData({ columnVisibilityModels: debouncedVisibility });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedVisibility]);

  const value = {
    fbSignIn,
    logout,
    currentUser,
    patchUserData,
    getColumnVisibility,
    updateColumnVisibility,
    saving,
    error,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
