import React, { useState, useEffect } from "react";
import copy from "../../../../Helpers/copy";
import { flattenObject } from "../../../../Helpers/flattenObj";
import { DataGridPro } from "@mui/x-data-grid-pro";
import GridToolbar from "./GridToolbar";
import Box from "@mui/material/Box";

interface FeedbackDetailProps {
  data: any;
  job_id: string;
}

function FeedbackDetail({ data, job_id }: FeedbackDetailProps) {
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  useEffect(() => {
    if (!data) {
      return;
    }

    let dataCopy = copy(data);
    let mapped = Object.keys(dataCopy)?.map((i: any) => {
      return flattenObject(dataCopy[i]);
    });

    setRows(mapped);
  }, [data]);

  useEffect(() => {
    if (!rows) {
      return;
    }
    let uniqueKeys = Object.keys(
      rows.reduce(function (result: any, obj: any) {
        return Object.assign(result, obj);
      }, {})
    );

    let columnData = uniqueKeys.map((i: any) => {
      return {
        field: i,
        headerName: i,
        width: 200,
      };
    });

    setColumns([...columnData]);
  }, [rows]);

  const getFileName = () => {
    let now = Date.now();
    let filename = `${job_id}_feedback_${now}`;

    return filename;
  };

  return (
    <>
      <Box
        sx={{
          // height: "calc(100vh - 240px)",
          // minHeight: "500px",
          // maxWidth: "1400px",
          // minWidth: "700px",
          // width: "80vw",
          // p: 2,
          // pl: 3,
          minHeight: "500px",
          height: "calc(100vh - 128px)",
          background: "rgba(0, 0, 0, 0.16)",
        }}
      >
        <DataGridPro
          sx={{ background: "white" }}
          rows={rows}
          columns={columns}
          getRowId={(row) => row.name}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              filename: getFileName,
            },
          }}
        />
      </Box>
    </>
  );
}

export default FeedbackDetail;
