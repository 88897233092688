import React from "react";
import FeedbackDetail from "../FeedbackDetail";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
interface Props {
  open: boolean;
  handleOpen: any;
  data: any;
  job_id: string;
  database: string;
}

function FeedbackDialog({ open, handleOpen, data, job_id, database }: Props) {
  const handleDialogClick = (e: any) => {
    e.stopPropagation();
  };
  return (
    <Dialog
      onClick={(e) => handleDialogClick(e)}
      onClose={() => handleOpen()}
      open={open}
      maxWidth="xl"
    >
      <Box sx={{ ml: 3, mt: 1 }}>
        <Typography paragraph>
          <Typography component="span" sx={{ fontWeight: "700" }}>
            {database}
          </Typography>{" "}
          · {job_id}
        </Typography>
      </Box>
      <IconButton
        onClick={() => handleOpen()}
        sx={{ position: "absolute", top: 2, right: 2, zIndex: "5000" }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ maxWidth: "calc(100vw-64px)", paddingTop: 0 }}>
        <FeedbackDetail job_id={job_id} data={data} />
      </DialogContent>
    </Dialog>
  );
}

export default FeedbackDialog;
