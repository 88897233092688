import React, { createContext, useContext, useState } from "react";
import copy from "../Helpers/copy";
const JobDetailContext = createContext<any>(null);
export const useJobDetails = () => useContext(JobDetailContext);

export function JobDetailsProvider({ children }: any) {
  const [jobDetails, setJobDetails] = useState<any>({
    "sigvaris-qa-db": [],
    "sigvaris-prod": [],
    "cartier-test": [],
    cartierprodwest: [],
  });
  const [newJobs, setNewJobs] = useState<number>(0);

  const addJobDetails = ({ job }: any) => {
    let state_copy = copy(jobDetails);
    if (state_copy[job.database].find((item: any) => item["id"] === job.id)) {
      return;
    }
    state_copy[job.database] = [...state_copy[job.database], job];
    setJobDetails(state_copy);
    setNewJobs((prev) => (prev += 1));
  };

  const removeJobDetails = ({ job }: any) => {
    let state_copy = copy(jobDetails);
    let new_state = state_copy[job.database].filter(function (obj: any) {
      return obj.id !== job.id;
    });

    state_copy[job.database] = new_state;
    setJobDetails(state_copy);
  };

  const value = {
    jobDetails,
    addJobDetails,
    removeJobDetails,
    newJobs,
    setNewJobs,
  };

  return (
    <JobDetailContext.Provider value={value}>
      {children}
    </JobDetailContext.Provider>
  );
}
