import React, { useState, useEffect } from "react";
import { useJobDetails } from "../../Context/JobDetailContext";
import { useAuth } from "../../Context/AuthContext";
import { fetchJob, getZipURL, downloadFile } from "../../Services/Job/Job";
import { findNestedObj, buildFolderStructure } from "../../Helpers/jobDetails";
import copy from "../../Helpers/copy";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Collapsible from "../../Components/Collapsible";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import MeasureModal from "./MeasureModal";
import SizeWarning from "./SizeWarning";

const isBiggerThan100mb = (size: string) => {
  if (size.includes("MiB")) {
    let fileSize = Number(size.replace("MiB", ""));
    if (fileSize >= 100.1) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

function ListItem({
  listItem,
  downloadFileSAS,
  getSize,
  database,
  currentUser,
}: any) {
  const { name, children, files, path } = listItem;
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadURL, setDownloadURL] = useState<null | string>(null);
  const handleFileDownload = async () => {
    setLoading(true);
    let token = await currentUser.getIdToken();
    let splitPath = path.slice(0, -1);
    let url = await getZipURL(database, splitPath, token);
    if (url?.error) {
    } else {
      setDownloadURL(url.url);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ ml: 1 }}>
      <Collapsible
        header={name}
        downloadPath={downloadURL}
        handleFileDownload={
          name.toLowerCase() === "jobs" ? null : handleFileDownload
        }
        loading={loading}
      >
        {files && (
          <File
            item={files}
            url={path}
            downloadFileSAS={downloadFileSAS}
            getSize={getSize}
          />
        )}
        {Array.isArray(children) && (
          <List
            list={children}
            downloadFileSAS={downloadFileSAS}
            getSize={getSize}
            database={database}
            currentUser={currentUser}
          />
        )}
      </Collapsible>
    </Box>
  );
}

function File({ item, url, downloadFileSAS, getSize }: any) {
  return (
    <Box sx={{ ml: 1 }}>
      {item.map((e: any) => {
        return e.map((f: any) => {
          // const href = handleFile(url, f);
          return (
            <Box key={f} sx={{ display: "flex" }}>
              <Button
                component={"button"}
                onClick={() => downloadFileSAS(url, f)}
                // href={href}
                // download
                // target="_blank"

                sx={{
                  display: "block",
                  color: "primary.main",
                  textDecoration: "none",
                  textTransform: "none",
                  "&:hover": {
                    opacity: "0.5",
                    cursor: "pointer",
                  },
                }}
              >
                {f} -
                <Typography
                  component="span"
                  sx={{
                    color: isBiggerThan100mb(getSize(f))
                      ? "#ed6c02"
                      : "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {" "}
                  {getSize(f)}
                </Typography>
              </Button>
              {isBiggerThan100mb(getSize(f)) && <SizeWarning />}
            </Box>
          );
        });
      })}
    </Box>
  );
}

function List({ list, downloadFileSAS, getSize, database, currentUser }: any) {
  return (
    <Box sx={{ ml: 1 }}>
      {list.map((listItem: any) => (
        <ListItem
          key={listItem.name ? listItem.name : listItem}
          listItem={listItem}
          downloadFileSAS={downloadFileSAS}
          getSize={getSize}
          database={database}
          currentUser={currentUser}
        />
      ))}
    </Box>
  );
}

interface Props {
  job: any;
  job_id: string;
  database: string;
}

function JobDetail({ job, job_id, database }: Props) {
  const { removeJobDetails } = useJobDetails();
  const { currentUser } = useAuth();
  const [rawData, setRawData] = useState<any | null>(null);
  const [data, setData] = useState<any | null>(null);
  const [folders, setFolders] = useState<any>([]);
  const [measureModalOpen, setMeasureModalOpen] = useState<boolean>(false);

  const handleClose = () => {
    setMeasureModalOpen(false);
  };

  const handleOpen = () => {
    setMeasureModalOpen(true);
  };

  const createLink = (downloadURL: any, fileName: string) => {
    const downloadLink = document.createElement("a");
    downloadLink.setAttribute("target", "_blank");
    document.body.appendChild(downloadLink);
    downloadLink.download = fileName;
    downloadLink.href = downloadURL;
    downloadLink.click();
  };

  const getSize = (filename: string) => {
    let dataCopy = copy(rawData);
    let returnData = null;
    dataCopy.forEach((i: any) => {
      if (i.filename.includes(filename)) {
        returnData = i.size;
      }
    });
    return returnData;
  };

  const fetchData = async () => {
    let token = await currentUser.getIdToken();
    fetchJob(job_id, database, token).then((res: any) => {
      setRawData(res);
      res.forEach(function (item: any) {
        buildTree(item.filename.split("/"));
        setData(tree);
      });
    });
  };

  const downloadFileSAS = async (path: any, filename: any) => {
    // return `${process.env.REACT_APP_SERVER_URL}/jobs/download?job_id=${job_id}&path=${path}&filename=${filename}&database=${database}`;
    let token = await currentUser.getIdToken();
    let filePath = `${path}${filename}`;
    let sas = true;
    await downloadFile(database, filePath, token, sas).then((res: any) => {
      createLink(res, filename);
    });
  };

  let tree = {
    // Represents the "root" directory, like in a filesystem.
    root: {
      absolute_path: "",
      files: [],
    },
  };

  function buildTree(parts: any) {
    let lastDir = "root";
    let abs_path = "";

    parts.forEach(function (name: any) {
      // It's a directory
      if (name.indexOf(".") === -1) {
        lastDir = name;
        abs_path += lastDir + "/";

        if (!tree[name]) {
          tree[name] = {
            absolute_path: abs_path,
            files: [],
          };
        }
      } else {
        if (!tree[lastDir].files.includes(name)) {
          tree[lastDir].files.push(name);
        }
      }
    });
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  useEffect(() => {
    let folder_init = findNestedObj(data, "absolute_path");
    let folderData = buildFolderStructure(folder_init, data);
    setFolders(folderData);
  }, [data]);

  const getResultsURL = () => {
    if (database === "sigvaris-qa-db") {
      return `${process.env.REACT_APP_SIGVARIS_QA_DB_URL}/assets/downloadexcelfile/${job_id}`;
    } else if (database === "sigvaris-prod") {
      return `${process.env.REACT_APP_SIGVARIS_PROD_URL}/assets/downloadexcelfile/${job_id}`;
    } else {
      return "";
    }
  };

  return (
    <>
      <Paper sx={{ mb: 2 }} elevation={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
          }}
        >
          <Box sx={{ ml: 2 }}>
            <Typography variant="caption" sx={{ color: "#757575" }}>
              {database}
            </Typography>
            <Typography>• {job_id}</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                removeJobDetails({ job: job });
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <>
          <Divider sx={{ mx: 1 }} />
          <Box sx={{ m: 2, display: "flex" }}>
            {database.includes("sigvaris") && (
              <>
                <Button
                  component="a"
                  href={getResultsURL()}
                  download
                  size="small"
                  target="_blank"
                  startIcon={<SaveAltIcon />}
                >
                  Results Excel
                </Button>
                <MeasureModal
                  job={job}
                  open={measureModalOpen}
                  handleClose={handleClose}
                  database={database}
                />
                <Button
                  size="small"
                  startIcon={<ZoomInIcon />}
                  onClick={() => handleOpen()}
                  disabled={job.mode === 4}
                >
                  Measures
                </Button>
              </>
            )}
          </Box>
        </>
        {folders && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Browse files
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <List
                  list={folders}
                  downloadFileSAS={downloadFileSAS}
                  getSize={getSize}
                  database={database}
                  currentUser={currentUser}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          sx={{
            ".MuiCollapse-root": { transitionDuration: "300ms !important" },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Browse data
          </AccordionSummary>
          <AccordionDetails>
            <Box
              component="pre"
              sx={{
                background: "rgb(30, 30, 30)",
                p: 2,
                position: "relative",
                maxWidth: "474px",
                overflowY: "scroll",
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 2, right: 2 }}
                onClick={() =>
                  navigator.clipboard.writeText(JSON.stringify(job, null, 2))
                }
              >
                <ContentCopyIcon sx={{ color: "white" }} />
              </IconButton>
              <Box component="code" sx={{ color: "white" }}>
                {JSON.stringify(job, null, 2)}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
}

export default JobDetail;
