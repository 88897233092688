import React, { useState, useEffect } from "react";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import CachedIcon from "@mui/icons-material/Cached";
import PublicIcon from "@mui/icons-material/Public";
import FeedbackExportOptions from "../FeedbackExportOptions";
import TimezoneDropdown from "../../../TimezoneDropdown";
import timezones from "../../../../mockData/timezones";

interface Props {
  getJob: any;
  database:
    | "sigvaris-qa-db"
    | "sigvaris-prod"
    | "cartier-test"
    | "cartierprodwest";
  setTimezone?: any;
  timeZone?: any;
  globalTimezone?: any;
  setGlobalTimezone?: any;
  tzInputValue?: any;
  setTzInputValue?: any;
  feedbackExport?: boolean;
  jobs?: any;
  backgroundLoad: boolean;
}

function GridToolbar({
  getJob,
  database,
  setTimezone,
  timeZone,
  globalTimezone,
  setGlobalTimezone,
  tzInputValue,
  setTzInputValue,
  feedbackExport,
  jobs,
  backgroundLoad,
}: Props) {
  const [timerActive, setTimerActive] = useState<boolean>(false);
  const [tick, setTick] = useState(0);

  const time = 10000;

  useEffect(() => {
    getJob(database);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tick]);

  useEffect(() => {
    if (timerActive) {
      const interval = setInterval(() => {
        setTick((prev: any) => prev + 1);
      }, time);
      return () => clearInterval(interval);
    }
  }, [timerActive]);

  const handleTimezone = () => {
    if (timeZone) {
      setGlobalTimezone(null);
    } else {
      let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let tzMatch = null;

      timezones.forEach((i: any) => {
        if (i.tzCode === tz) {
          tzMatch = i;
        }
      });

      setGlobalTimezone(tzMatch);
    }
  };

  return (
    <GridToolbarContainer sx={{ my: 1 }}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      {feedbackExport && (
        <FeedbackExportOptions
          jobs={jobs}
          database={database}
          timeZone={globalTimezone}
        />
      )}
      <Button
        onClick={() => getJob(database)}
        size="small"
        startIcon={<RefreshIcon fontSize="small" />}
      >
        Refresh
      </Button>
      <Button
        variant={timerActive ? "contained" : "text"}
        size="small"
        startIcon={
          <CachedIcon
            fontSize="small"
            sx={{
              animation: backgroundLoad ? "spin 2s linear infinite" : "none",
              "@keyframes spin": {
                "0%": {
                  transform: "rotate(360deg)",
                },
                "100%": {
                  transform: "rotate(0deg)",
                },
              },
            }}
          />
        }
        onClick={() => setTimerActive(!timerActive)}
      >
        Timed Refresh
      </Button>
      {setTimezone && (
        <Button
          onClick={() => handleTimezone()}
          size="small"
          startIcon={<PublicIcon fontSize="small" />}
        >
          Localize
        </Button>
      )}
      <TimezoneDropdown
        key={globalTimezone}
        globalTimezone={globalTimezone}
        setGlobalTimezone={setGlobalTimezone}
        tzInputValue={tzInputValue}
        setTzInputValue={setTzInputValue}
      />
    </GridToolbarContainer>
  );
}

export default GridToolbar;
