import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
interface Props {
  message?: string;
  loading?: boolean;
  warning?: boolean;
}

const Toast = ({ message, loading, warning }: Props) => {
  return (
    <>
      <Snackbar
        open={loading}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        // message={message}
        sx={{
          "&.MuiSnackbarContent-message": { color: warning ? "red" : null },
        }}
      >
        <Alert icon={false} severity={warning ? "error" : "success"}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Toast;
